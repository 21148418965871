import React from 'react';
import styled from 'styled-components';
import { View } from '../App';

const StyledBar = styled.div`
    width: 100%;
    background: rgba(255,255,255,1);
    position: fixed;
    z-index:999;
    top: 0;
    left: 0;
    right: 0;
    padding: 2em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    justify-items: start;
    box-sizing: border-box;

    h4 {
      margin: 0;
      color: rgb(18,145,255) !important;
      text-align: center;
      justify-self: center;
    }

    button {
        background: rgb(18,145,255);
        color: white;
        font-weight: bold;
        border: none;
        padding: 0.5em 1em;
        cursor: pointer;
        width: auto;

        &:hover {
            background: rgb(18,145,200);
        }

        &.leftButton {
            justify-self: start;
        }

        &.rightButton {
            justify-self: end;
        }
    }
`;

interface StatusBarInterface {
    handleViewChange: (newView: View) => void;
    leftButtonLabel?: string;
    leftButtonAction?: () => void;
    rightButtonLabel?: string;
    rightButtonAction?: () => void;
}

const StatusBar: React.FC<StatusBarInterface> = (props) => {
    const { handleViewChange, leftButtonLabel, leftButtonAction, rightButtonLabel, rightButtonAction } = props;

    return (
        <StyledBar>
            {
                leftButtonLabel && leftButtonAction 
                ?
                <button className="leftButton" onClick={leftButtonAction}>{leftButtonLabel}</button>
                : <div></div>
            }
            <h4>BEP GALLERY</h4>
            {
                rightButtonLabel && rightButtonAction
                ? <button className="rightButton" onClick={rightButtonAction}>{rightButtonLabel}</button>
                : <div></div>
            }
        </StyledBar>
    )
}

export default StatusBar;