import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, setDoc, getDocs, collection, query, where } from 'firebase/firestore';
import styled from 'styled-components';
import Select, {MultiValue} from 'react-select';
import { map } from '@firebase/util';
import { View } from '../App';
import GridIcon from '../icons/view-grid.svg';
import AnimationIcon from '../icons/animation-play.svg';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

const StyledFilters = styled.div`
    form {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
    .buttonGroup {
        margin-top: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
    }

    input {
        padding: 0.6em 0.8em;
        font-size: 1em;
        border-radius: 4px;
        border: 1px solid grey;
    }

    button {
    margin-top: 1em;
    border: none;
    border-radius: 0.2em;
    background: white;
    color: rgb(0,112,245);
    font-size: 1.1em;
    font-weight: bold;
    padding: 0.5em 1.1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    img {
        height: 1.5em;
        margin-right: 0.5em;
    }

    &:disabled {
        color: grey;
        cursor: not-allowed;
    }
  }
`;

const DatePickerLine = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 2em;
    justify-content: stretch;

    .react-datepicker-wrapper, .react-datepicker__input-container{
        width: 100%;
    }

    .react-datepicker__input-container input {
        width: 87.5%;
    }

    p {
        color: white;
    }
`;

interface FilterInterface {
    handleViewChange: (newView: View, selfies: Selfie[]) => void;
}

interface Option {
    value: string,
    label: string
}

export interface Selfie {
    country: string,
    level: string,
    partner: string,
    url: string,
    id: string,
    email: string,
    submittedAt: string,
}


const Filter: React.FC<FilterInterface> = (props) => {

    const { handleViewChange } = props;

    const [ countries, setCountries ] = useState<Option[]>([]);
    const [ partners, setPartners ] = useState<Option[]>([]);
    const [ levels, setLevels ] = useState<Option[]>([]);

    const [ countriesFilter, setCountriesFilter ] = useState<string[]>([]);
    const [ partnersFilter, setPartnersFilter ] = useState<string[]>([]);
    const [ levelsFilter, setLevelsFilter ] = useState<string[]>([]);
    const [ emailFilter, setEmailFilter ] = useState<string>("");
    const [ startDate, setStartDate] = useState<Date>();
    const [ endDate, setEndDate] = useState<Date>();

    const [ filteredSelfies, setFilteredSelfies ] = useState<Selfie[]>([]);

    //fetch countries
    const fetchCountries = async() => {
        const querySnapshot = await getDocs(query(collection(db, 'countries')));

        const snapShot = await querySnapshot;

        if (snapShot.empty) {
        console.log("error fetching countries")
        } else {
        let countriesCopy: Option[] = [];
        let countriesFilterCopy: string[] = [];
        snapShot.forEach((doc) => {
            countriesCopy?.push({value: doc.data().name, label: doc.data().name})
            countriesFilterCopy.push(doc.data().name)
        })
        setCountries(countriesCopy)
        setCountriesFilter(countriesFilterCopy)
        }
    }

    useEffect(() => {
        fetchCountries();
    }, []);

    // fetch partners
    const fetchPartners = async() => {
        let partnerOptions: Option[] = [];

        const querySnapshot = await getDocs(query(collection(db, 'partners')));

        const snapShot = await querySnapshot;

        if (snapShot.empty) {
            console.log("no partners available for this country")
        } else {
            snapShot.forEach((doc) => {
                if (!partnerOptions.includes({label: doc.data().name, value: doc.data().name})) {
                    partnerOptions.push({label: doc.data().name, value: doc.data().name})            
                }
            }
        )}
        setPartners([]);
        setPartners(partnerOptions)
    }

    useEffect(() => {
        fetchPartners();
    }, [countriesFilter]);

    // fetch levels
    const fetchLevels = async() => {
        let levelOptions: Option[] = [];

        const querySnapshot = await getDocs(query(collection(db, 'levels')));

        const snapShot = await querySnapshot;

        if (snapShot.empty) {
            console.log("no levels available")
        } else {
            snapShot.forEach((doc) => {
                if (!levelOptions.includes({label: doc.data().name, value: doc.data().name})) {
                    levelOptions.push({label: doc.data().name, value: doc.data().name})
                }
        })
        }
        setLevels(levelOptions)
    }

    useEffect(() => {
        fetchLevels();
    }, []);

    //fetch images
    const isInCountryFilter: (selfie: Selfie) => boolean = (selfie) => {
        if (countriesFilter.includes(selfie.country)) {
            return true;
        } else {
            return false;
        }
    }
    const fetchImages = async() => {
        const querySnapshot = await getDocs(query(collection(db, 'selfies')));

        const snapShot = await querySnapshot;

        if (snapShot.empty) {
            console.log("no selfies found")
        } else {
            let selfieCount = 0;
            let filteredSelfiesCopy: Selfie[] = [];
            snapShot.forEach((doc) => {
                let survivedCountryFilter: Selfie[] = [];
                // check if country is in countriesFilter
                if (countriesFilter.length > 0) {
                    console.log("countries filter has to be applied")
                    if (countriesFilter.includes(doc.data().country)) {
                        console.log(`${doc.data()} will be added`)
                        survivedCountryFilter.push({...doc.data(), id: doc.id} as Selfie)
                    }
                } else {
                    console.log("no countries filter is applied, all survive1")
                    survivedCountryFilter.push(doc.data() as Selfie)
                }
                console.log(survivedCountryFilter);

                // now take all that survived the countryfilter and apply partnerFilter
                let survivedPartnerFilter: Selfie[] = [];
                survivedCountryFilter.map(selfie => {
                    // check if partner is in partnerFilter
                    if (partnersFilter.length > 0) {
                        if (partnersFilter.includes(selfie.partner)) {
                            survivedPartnerFilter.push(selfie)
                        }
                    } else {
                        survivedPartnerFilter.push(selfie)
                    }
                })

                // now take all that survived the partnerFilter and apply levelFilter
                let survivedLevelFilter: Selfie[] = [];
                survivedPartnerFilter.map(selfie => {
                    if (levelsFilter.length > 0) {
                        if (levelsFilter.includes(selfie.level)) {
                            survivedLevelFilter.push(selfie)
                        }
                    } else {
                        survivedLevelFilter.push(selfie)
                    }
                })

                let survivedEmailFilter: Selfie[] = [];
                survivedLevelFilter.map(selfie => {
                    if (emailFilter !== "") {
                        if (selfie.email === emailFilter) {
                            survivedEmailFilter.push(selfie)
                        }
                    } else {
                        survivedEmailFilter.push(selfie)
                    }
                })

                let survivedDateFilter: Selfie[] = [];
                survivedEmailFilter.map(selfie => {
                    if (startDate && endDate) {
                        if (selfie.submittedAt !== "") {
                            if (parseFloat(selfie.submittedAt) >= startDate.getTime() && parseFloat(selfie.submittedAt) <= endDate.getTime())
                            survivedDateFilter.push(selfie)
                        }
                    } else {
                        survivedDateFilter.push(selfie)
                    }
                })

                if (survivedDateFilter.length > 0) {
                    filteredSelfiesCopy.push(survivedEmailFilter[0])
                }
            })
            setFilteredSelfies(filteredSelfiesCopy)
            console.log(selfieCount)
        }
    }

    useEffect(() => {
        console.log("fetch images");
        fetchImages()
    }, [countriesFilter, partnersFilter, levelsFilter, emailFilter, startDate, endDate])

    return (
        <StyledFilters>
            <h2>Apply one or multiple filters to the selfies</h2>
            <form>
                <Select
                    placeholder="Select Country(s)"
                    isMulti
                    name="countries"
                    options={countries}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                        let selectedCountries: string[] = []
                        e.map(selection => {
                            selectedCountries.push(selection.value)
                        })
                        setCountriesFilter(selectedCountries)
                    }}
                />
                <Select
                    placeholder="Select Partner(s)"
                    isMulti
                    name="partners"
                    options={partners}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                        let selectedPartners: string[] = []
                        e.map(selection => {
                            selectedPartners.push(selection.value)
                        })
                        setPartnersFilter(selectedPartners)
                    }}
                />
                <Select
                    placeholder="Select Level(s)"
                    isMulti
                    name="levels"
                    options={levels}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                        let selectedLevels: string[] = []
                        e.map(selection => {
                            selectedLevels.push(selection.value)
                        })
                        setLevelsFilter(selectedLevels)
                    }}
                />
                <input
                    placeholder="Enter Email"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                />
                <>
                <p style={{color: "white", margin: 0, marginTop: '1em', fontWeight: "bold"}}>Submission Date</p>
                <DatePickerLine>
                    <div>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date as Date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="From"
                        dateFormat="MMMM d, yyyy"
                    />
                    </div>
                    <div>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date as Date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText="To"
                        dateFormat="MMMM d, yyyy"
                    />
                    </div>
                </DatePickerLine>
                </>
            </form>
            <p style={{color: "white", textAlign: "center", fontWeight: "bold"}}>{filteredSelfies.length} Selfies found</p>
            <div className="buttonGroup">
                <button disabled={filteredSelfies.length == 0} onClick={() => handleViewChange(View.Grid, filteredSelfies)}><img src={GridIcon} />Grid View</button>
                <button disabled={filteredSelfies.length == 0} onClick={() => handleViewChange(View.Animation, filteredSelfies)}><img src={AnimationIcon} />Animated Gallery</button>
            </div>
        </StyledFilters>
    )
}

export default Filter;