import React, { useState } from 'react';
import { StyledErrorMessage, StyledInputGroup, StyledLogin } from './Login';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification } from 'firebase/auth';
import { auth, db } from '../firebase';
import { useUserStore } from '../store/userStore';
import styled from 'styled-components';

export const StyledSignUpForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1em;
`;

interface SignUpInterface {
    handleBackToLogin: () => void
}

const SignUp: React.FC<SignUpInterface> = (props) => {

    const { handleBackToLogin } = props;

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const { user, setUser } = useUserStore();
    const [ error, setError ] = useState<string>('');

    const handleSignUp = (e: any) => {
        e.preventDefault();
        createUser();
    }

    const createUser = () => {
        //validate email and pw
        if (email && email.includes("@apple.com") && password) {
            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                sendEmailVerification(userCredential.user)
                handleBackToLogin()
            })
            .catch(error => {
                setError(error.code)
                setTimeout(() => {
                    setError('')
                }, 3500)
            })
        } else if (!email?.includes("@apple.com")) {
            setError("auth/no-apple")
            setEmail("")
        } else if ("password") {
            setError("auth/weak-password")
            setPassword("")
        }
    }

    return (
        <StyledLogin>
            <h2>Create your account</h2>
             <StyledSignUpForm>
                <input type="text" value={email ? email : ""} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                <input type="password" value={password ? password : ""} onChange={e => setPassword(e.target.value)} placeholder="Password"/>
                <button onClick={(e) => handleSignUp(e)}>Sign Up</button>
             </StyledSignUpForm>
             {
             error &&
                 <StyledErrorMessage>
                     {error === 'auth/no-apple' && 'You can only sign up using a corporate apple.com Mail Address'}
                     {error === 'auth/user-not-found' && 'No user found, click "Sign Up" to open a new profile'}
                     {error === 'auth/invalid-email' && 'Please enter a valid email address'}
                     {error === 'auth/wrong-password' && 'Wrong password'}
                     {error === 'auth/internal-error' && 'An error occured, please check your input'}
                     {error === 'auth/weak-password' && 'Please select a stronger password with at least 6 characters'}
                     {error === 'auth/email-already-in-use' && 'This email is already associated with a user'}
                 </StyledErrorMessage>
             }
             <p>Got credentials?</p> 
             <a onClick={() => handleBackToLogin()}>Back to Login Form</a>
             
         </StyledLogin>
    )
}

export default SignUp;