import { User } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { useState } from 'react';
import create from 'zustand';
import { auth, db } from '../firebase';


interface UserState {
    user: User | undefined;
    setUser(user: User | undefined): void;
}

export const useUserStore = create<UserState>(set => ({
        user: undefined,
        setUser: (user: User) => set(state => ({user: user}))
    })
)


