import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Selfie } from './Filter';
import { db } from '../firebase';
import { doc, deleteDoc, where, collection } from 'firebase/firestore';
import DeleteIcon from '../icons/delete.svg';
import DownloadIcon from '../icons/download.svg';
import Slider from 'react-input-slider';

interface StyledGridInterface {
    scale: number
}

const StyledGrid = styled.div<StyledGridInterface>`
    width: 100%;
    padding: 10em 2em 5em 2em;
    box-sizing: border-box;
    overflow: hidden;
    display: grid;
    grid-template-columns: ${props => `repeat(${props.scale}, 1fr)`};
    gap: 0.5em;
    /* min-height: ${(4/3 * (window.innerWidth / 9)) * 4}px; */
    grid-auto-rows: min-content;
    /* margin-top: ${4/3 * (window.innerWidth / 9)}px; */

    img {
        max-width: calc(100% - 20px);
        
        &.editMode {
            border: 10px solid white;
            cursor: pointer;
        }

        &.selected {
            border-color: blue;;
        }
    }
`;

const StyledSlider = styled.div`
    background: white;
    padding: 1em;
    position: fixed;
    bottom: 2em;
    right: 2em;
`;

const StyledSelfie = styled.div`
    max-width: 100%;
    position: relative;
    img {
        max-width: 100%;
    }
    button {
        position: absolute;
        width: 3em;
        height: 3em;
        bottom: 0.25em;
        left: 0;
        border-radius: 0;
        border: 0;
        background: white;
        cursor: pointer;

        &.download {
            left: calc(100% - 3em);
            right: 0;
        }

        &.hidden {
            display: none;
        }
    }
`;

interface GridInterface {
    selfies: Selfie[]
    screenshotMode: boolean
}

const Grid: React.FC<GridInterface> = (props) => {
    const { selfies, screenshotMode } = props

    const [selfiesState, setSelfiesState] = useState<Selfie[]>(selfies);

    const [size, setSize] = useState<{x: number, y: number}>({x: 8, y: 0})

    const deleteSelfie = async(imageID: string) => {
        if (window.confirm("Do you really want to delete this image?") == true) {
            await deleteDoc(doc(db, 'selfies', imageID))
            setSelfiesState(selfiesState.filter(selfie => selfie.id != imageID))
            console.log("deleted image")
          } else {
            console.log("cancelled");
          }
    }

    const downloadSelfie = async(uri: string, name: string) => {
        fetch(uri)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `${name.split('/o/')[1].split("alt")[0]}.jpg`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => alert('oh no!'));
    }

    return (
        <StyledGrid scale={size.x}>
            {
                selfiesState.map(selfie => 
                    <StyledSelfie key={selfie.url}>
                        <img src={selfie.url} alt={selfie.url}/>
                        <button className={screenshotMode ? "hidden" : "delete"} onClick={() => deleteSelfie(selfie.id)}><img src={DeleteIcon} /></button>
                        <button className={screenshotMode ? "hidden" : "download"} onClick={() => downloadSelfie(selfie.url, selfie.url)}><img src={DownloadIcon} /></button>
                    </StyledSelfie>
                )
            }
            <StyledSlider>
                <Slider axis="x" x={size.x} xmin={5} xmax={20} onChange={({x}) => setSize({...size, x})} />
            </StyledSlider> 
        </StyledGrid>
    )
}

export default Grid;