import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebase';
import { doc, deleteDoc } from 'firebase/firestore';
import { Selfie } from './Filter';

interface PictureProps {
    img: Selfie;
    id?: string;
    active?: boolean;
    animated?: boolean;
}

interface StyledPictureProps {
    id: string;
    active?: boolean;
    animated?: boolean;
}

const StyledPicture = styled.div<StyledPictureProps>`
    width: 100%;

    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        padding-bottom: calc(9/16 * 100%):
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
        &.animated {
            animation: myAnim 5s ease 0s 1 normal forwards;
        }

        @keyframes scaleup {
            0% {
                transform: scale(1)
            }
            50% {
                transform: scale(2) translateY(3em) translateX(3em);
                box-shadow: 0 0 20px rgba(0,0,0,0.8)
            }
            100% {
                transform: scale(1)
            }
        }

        @keyframes myAnim {
            0% {
                transform: rotateX(0deg);
            }

            30% {
                transform: rotateX(360deg);
            }

            100% {
                transform: rotateX(360deg);
            }
        }
    }
`;

export const Picture: FC<PictureProps> = (props) => {
    const { img, id, active, animated } = props;
    console.log({img}+' rendered');
    const deletePicture = async(imageID: string) => {
        console.log("try to delete image with id " + imageID)
        if (window.confirm("Do you really want to delete this image?") == true) {
            await deleteDoc(doc(db, 'selfies', imageID))
            console.log("deleted image")
          } else {
            console.log("cancelled");
          }
       
    }
    return (
        <StyledPicture id={img.id} active={active}>
            <img src={img.url} alt='selfie' id={img.id} className={animated ? 'animated' : ''} />
        </StyledPicture>
    )
}