import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useUserStore } from '../store/userStore';
import { auth } from '../firebase';
import SignUp from './SignUp';
import NextIcon from '../icons/play.svg';
import ResetPW from './ResetPW';

export const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
  input {
    font-size: 1.2em;
    padding: 1.2em;  
    text-align: center;
    background: rgba(255, 255, 255, 1);
    color: rgb(0,112,245);
    font-weight: bold;
    border: none;
  }
  .privacyAgreement input {
    font-size: 0.5em;
    margin-right: 0.7em;
  }
  button {
    margin-top: 1em;
    border: none;
    border-radius: 0.2em;
    background: white;
    color: rgb(0,112,245);
    font-size: 1.1em;
    font-weight: bold;
    padding: 0.5em 1.1em;
    cursor: pointer;

    &:disabled {
        color: grey;
        cursor: not-allowed;
    }
  }
  p {
    font-weight: bold;
    margin-top: 0.4em;
  }
  .privacyAgreement {
    margin-top: 1em;
  }
  .errorMessage {
    font-weight: bold;
    font-size: 0.9em;
    line-height: 2em;
    text-decoration: underline;
  }

  p {
    margin-top: 2em !important;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  p, a {
    color: white;
    font-weight: normal;
    font-size: 0.9em;
    line-height: 1.4em;
    margin: 0;
  }
`;

export const StyledErrorMessage = styled.span`
    color: white;
    font-weight: bold;
    padding: 0.5em 0 1em 0;
    font-size: 0.8em;
`;

export const StyledSuccessMessage = styled.span`
    color: green;
    font-weight: bold;
    padding: 0.5em 0 1em 0;
    font-size: 0.8em;
`;

export const StyledInputGroup = styled.div`
    display: flex;
    align-items: stretch;

    button {
        margin-top: 0;
        border-radius: 0;
        background: rgba(255, 255, 255, 1);
        border-left: 2px solid rgb(0,112,245);
        padding: 0 1.5em;
    }
`;


const Login = () => {
    enum Field {
        Email, Password
    }
    enum Form {
        SignIn, SignUp, Reset
    }
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const { user, setUser } = useUserStore();
    const [ error, setError ] = useState<string>('');
    const [showField, setShowField] = useState<Field>(Field.Email);
    const [showForm, setShowForm] = useState<Form>(Form.SignIn);

    const pwFieldRef = useRef<HTMLInputElement>(null);

    const handleFieldChange = (e: any) => {
        e.preventDefault()
        setError("")
        // validate entered value
        // switch to next Field
        // or display error
        if (email?.includes("@grafication.at") || email?.includes("@apple.com")) {
            setShowField(Field.Password);
        } else {
            setError("auth/no-apple")
        }
    }

    useEffect(() => {
        if (error == "auth/user-not-found" || error == "auth/internal-error" || error == "auth/invalid-email") {
            setShowField(Field.Email)
            setEmail("")
        }
        setPassword("")
    }, [error]);

    return (
        <>
        {
            showForm == Form.SignIn &&

             <StyledLogin>
            <h2>Please enter your apple.com email</h2>
             <form>
                 {
                     showField == Field.Email &&
                     <StyledInputGroup>
                         <input type="text" value={email ? email : ""} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                         <button onClick={(e) => handleFieldChange(e)}><img src={NextIcon} style={{width: '1em', height: '1em'}} /></button>
                     </StyledInputGroup>
                 }
                 {
                     showField == Field.Password &&
                     <StyledInputGroup>
                         <input ref={pwFieldRef} type="password" value={password ? password : ""} onChange={e => setPassword(e.target.value)} placeholder="Password"/>
                         <button type="submit" onClick={(e) => {
                         e.preventDefault();
                         if (email != null && (email.includes("@grafication.at") || email.includes("@apple.com")) && password != null) {
                             signInWithEmailAndPassword(auth, email, password)
                             .then((userCredential) => {
                                if (userCredential.user.emailVerified) {
                                    setUser(userCredential.user)
                                } else {
                                    setError("auth/not-verified")
                                }
                             })
                             .catch(error => {
                                 setError(error.code);
                                 setTimeout(() => {
                                     setError('')
                                 }, 5000)
                         })
                         }
                     }} disabled={!email || !password}>Login</button>
                     </StyledInputGroup>
                 }
             </form>
             {
             error &&
                 <StyledErrorMessage>
                     {error === 'auth/no-apple' && 'Please enter a registered corporate apple.com Mail Address'}
                     {error === 'auth/user-not-found' && 'No user found, click the Register Link below to open a new profile'}
                     {error === 'auth/invalid-email' && 'Please enter a valid email address'}
                     {error === 'auth/wrong-password' && 'Wrong password'}
                     {error === 'auth/internal-error' && 'An error occured, please check your input'}
                     {error === 'auth/weak-password' && 'Please select a stronger password with at least 6 characters'}
                     {error === 'auth/email-already-in-use' && 'This email is already associated with a user'}
                     {error === 'auth/not-verified' && 'You have to verify your Email first'}
                 </StyledErrorMessage>
             }
             <p>Get access</p> 
             <a onClick={() => setShowForm(Form.SignUp)}>Register with your apple.com email</a>
             <a style={{'marginTop': '1em'}} onClick={() => setShowForm(Form.Reset)}>I forgot my password</a>
         </StyledLogin>
        } 
        {
            showForm == Form.SignUp &&
            <SignUp handleBackToLogin={() => setShowForm(Form.SignIn)} />
        }
        {
            showForm == Form.Reset &&
            <ResetPW handleBackToLogin={() => setShowForm(Form.SignIn)} />
        }
        </>
    )
}

export default Login;