import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Selfie } from './Filter';
import { Picture } from './Picture';
import { v4 as uuidv4 } from 'uuid';

const StyledGallery = styled.div`
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 0.5em;
    min-height: ${(4/3 * (window.innerWidth / 9)) * 4}px;
    grid-auto-rows: min-content;
    margin-top: ${4/3 * (window.innerWidth / 9)}px;
`;

const ProgressView = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}
 div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
`;

interface AnimationInterface {
    selfies: Selfie[]
}

const Animation: React.FC<AnimationInterface> = (props) => {
    const { selfies } = props;

    const [ imagesLoaded, setImagesLoaded ] = useState<boolean>(false);
    const [firstActiveFile, setFirstActiveFile] = useState<number>(0);
    const [ activeFiles, setActiveFiles ] = useState<Selfie[]>([]);

    const shuffle = (array: any[]) => {
        return array.sort(() => 0.5 - Math.random());
    }
    useEffect(() => {
        console.log('start animation');
        if (imagesLoaded && selfies.length > 0) {
            setInterval(() => {
                const activeFilesSlice = shuffle(selfies).slice(firstActiveFile, 27);
                setFirstActiveFile(firstActiveFile + 27);
                setActiveFiles(activeFilesSlice);
            }, 5000);
        }
    }, [imagesLoaded]);

    const checkImage = (path: string) =>
    new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(path)
        img.onerror = () => reject()

        img.src = path
    })

    useEffect(() => {
        const selfiesPromises = selfies.map(selfie =>
        new Promise((resolve, reject) => {
                const img = new Image()
                img.onload = () => resolve(selfie.url)
                img.onerror = () => reject()
        
                img.src = selfie.url
        }))
        return Promise.all(selfiesPromises)
        .then(() => setImagesLoaded(true)),
        () => console.error('could not load images')

        return () => setImagesLoaded(false)
    }, [selfies])

    return (
        <>
        { 
            imagesLoaded && activeFiles.length > 0
            ?
            <StyledGallery>
                {
                    activeFiles.map((elem, key) => {
                        // ADD DELAY HERE FOR EACH ELEMENT
                        return( 
                            <Picture img={elem} key={uuidv4()} animated/> 
                        )
                    })
                }
            </StyledGallery>
            :
            <>
            <ProgressView>
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>    
            </ProgressView>
            <p style={{color: "white", textAlign: "center"}}>Loading Selfies...</p>
            </>
        }
        </>
    )
} 

export default Animation;