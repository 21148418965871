import React, { useState } from 'react';
import { StyledErrorMessage, StyledLogin } from './Login';
import { StyledSignUpForm } from './SignUp';
import { sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebase';

interface ResetInterface {
    handleBackToLogin: () => void
}

const ResetPW: React.FC<ResetInterface> = (props) => {

    const { handleBackToLogin } = props;
    const [ email, setEmail ] = useState<string>("");
    const [emailMessage, setEmailMessage] = useState(false)

    const [ error, setError ] = useState<string>('');

    const passwordReset = async(email: string) => {      
        return await sendPasswordResetEmail(auth, email)
    }

    const handleReset = async(e: any) => {
        e.preventDefault();
        
        try {
            await passwordReset(email);
            setEmailMessage(true)
        } catch(error: any) {
            if(error.code === "auth/user-not-found") {
                alert('User not found, try again!')
                setEmail('')
            }
        }
    }

    return (
        <StyledLogin>
            <h2>Reset your Password</h2>
            {
                emailMessage ?
                <h3>The Email hast been sent. Check your Inbox!</h3> :
                (
                    <StyledSignUpForm>
                <input type="text" value={email ? email : ""} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                <button onClick={(e) => handleReset(e)}>Reset Password</button>
             </StyledSignUpForm>
                )
            }
             <a style={{"marginTop": "1em"}} onClick={() => handleBackToLogin()}>Back to Login Form</a>
             
         </StyledLogin>
    )
}

export default ResetPW