import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { Gallery } from './components/Gallery';
import { db } from './firebase';
import { getDownloadURL, listAll, ref, StorageReference } from 'firebase/storage';
import { getDocs, collection } from 'firebase/firestore';
import { useUserStore } from './store/userStore';
import Login from './components/Login';
import Filter, { Selfie } from './components/Filter';
import StatusBar from './components/StatusBar';
import Grid from './components/Grid';
import Animation from './components/Animation';

const StyledAppWrapper = styled.div`
  h1, h2, h3, h4 {
    color: white;
  }

  h1 {
    font-size: 3em;
    text-align: center;
  }

  background: linear-gradient(124deg, rgba(5,219,255,1) 0%, rgba(0,112,245,1) 50%, rgba(0,79,250,1) 100%);
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
`;

export enum View {
  Filter, Grid, Animation
}

function App() {
  const { user, setUser } = useUserStore();
  const [ itemRefs, setItemRefs] = useState<StorageReference[]>([]);
  const [files, setFiles] = useState<Selfie[]>([]);
  const [ fileStrings, setFileStrings ] = useState<any[]>();
  const [ view, setView ] = useState<View>(View.Filter);

  const [loading, setLoading] = useState<boolean>(false);

  const [ leftButtonLabel, setLeftButtonLabel ] = useState<string>("");
  const [ leftButtonAction, setLeftButtonAction ] = useState<() => void>();
  const [ rightButtonLabel, setRightButtonLabel ] = useState<string>();
  const [ rightButtonAction, setRightButtonAction ] = useState<() => void>();

  const [ screenshotMode, setScreenshotMode ] = useState<boolean>(true);

  useEffect(() => {
    if (view == View.Filter) {
      setLeftButtonLabel("Logout");
      setLeftButtonAction(() => () => setUser(undefined));
      setRightButtonLabel(undefined);
      setRightButtonAction(undefined)
    } else if (view == View.Grid) {
      setLeftButtonLabel("Back")
      setLeftButtonAction(() => () => setView(View.Filter))
      if (screenshotMode) {
        setRightButtonLabel("Edit Mode")
      } else {
        setRightButtonLabel("Clean Mode")
      }
      setRightButtonAction(() => () => setScreenshotMode(!screenshotMode))
    } else if (view == View.Animation) {
      setLeftButtonLabel("Back")
      setLeftButtonAction(() => () => setView(View.Filter))
      setRightButtonLabel(undefined)
      setRightButtonAction(undefined)
    }
  }, [view, screenshotMode]);
 
  // function onlyUnique(value: string, index: number, self: string[]) {
  //   return self.indexOf(value) === index;
  // }

  // const getURLs = async() => {
  //   setLoading(true);
  //   const querySnapshot = await getDocs(collection(db, 'selfies'));
  //   const filesCopy = files;
  //   await querySnapshot.forEach((doc) => {
  //     filesCopy.push(doc.data().url);
  //   })
  //   setFiles(filesCopy);
  //   preloadImages(filesCopy.filter(onlyUnique));
  //   setLoading(false);
  // }

  // const preloadImages = (urlList: string[]) => {
  //   let fragment = document.createDocumentFragment(); 
  //   for (let i = 0; i < urlList.length; i++) {
  //     const imgUrl = urlList[i];
  //     const linkEl = document.createElement('link');
  //     linkEl.setAttribute('rel', 'preload');
  //     linkEl.setAttribute('href', imgUrl);
  //     linkEl.setAttribute('as', 'image');
  //     fragment.appendChild(linkEl);
  //   }
  //   document.head.appendChild(fragment);
  // }

  // useLayoutEffect(() => {
  //   getURLs()
  // }, []);

  return (
    <StyledAppWrapper>
      {
        !user &&
        <>
          <h1>Welcome to the BEP Selfie Gallery</h1>
          <Login />
        </>
      }
      {
        user &&
        <StatusBar leftButtonLabel={leftButtonLabel} leftButtonAction={leftButtonAction} rightButtonLabel={rightButtonLabel} rightButtonAction={rightButtonAction} handleViewChange={(newView: View) => setView(newView)} />
      }
      {
        user && view == View.Filter &&
        <Filter 
          handleViewChange={(newView: View, selfies: Selfie[]) => {
            setView(newView)
            setFiles(selfies)
          }}
        />
      }
      {
        user && view == View.Grid &&
        <>
          <Grid selfies={files} screenshotMode={screenshotMode} />
        </>
      }
      {
        user && view == View.Animation &&
        <>
          <Animation selfies={files} />
        </>
      }
    </StyledAppWrapper>
  );
}

export default App;
